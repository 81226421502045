import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './Navbar.css';
import axios from 'axios'
import {
  NavLink
} from "react-router-dom";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home';
import PlayerDetails from './PlayerDetails';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
class App extends Component {
  render() {
    return (
      <div id="primary-nav">
      <Container>
      <Navbar bg="#92dd42 " variant="dark">
      <Navbar.Brand href="/">
        <img
          alt="FutScout"
          src={process.env.PUBLIC_URL + '/images/brand/icon.png'}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        {' FutScout'}
      </Navbar.Brand>
    </Navbar>
      <BrowserRouter>
      <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/players/:playerID" component={PlayerDetails} />
      </Switch>
      </BrowserRouter>
      </Container>
      </div>
    );
  }
}

export default App;
