import React, { Component } from "react";
import axios from 'axios'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image'
import Col from 'react-bootstrap/Col';

class PlayerDetails extends Component {
  constructor(props) {
    super(props);
    const { match: { params } } = props;
    this.state = {
      player: {}
    }
    axios.get(process.env.REACT_APP_API + `/api/players/${params.playerID}`)
      .then((player) => {
        this.setState({ player: player.data });
      });

  }

  render() {
    const { player } = this.state;
    let playerImage;
    if (player.id) {
      playerImage = <Image src={process.env.PUBLIC_URL + '/images/players/' + player.id + '.jpg'} rounded />;
    }
    return (<div>
      <Container>
        <Row> <Col>{playerImage}</Col> 
        <Col>{player.givenName}&nbsp;{player.surname}</Col></Row>
       
      </Container>
    </div>)
  };
}

export default PlayerDetails;